import { ProductVariant } from 'lib/algolia/types';

import { SearchResultsProps } from 'types/algolia';

// TODO [CH11557] Placement of types in util

export type ImageSuggestionsContainerProps = {
  closeSuggestionsContainer: () => void;
  hits: ProductVariant[];
  isShowing: boolean;
};

export type ImageSuggestionsHeadingProps = {
  isShowing: boolean;
  searchResults: SearchResultsProps;
};

export const isUserSearching = (
  previousProps: ImageSuggestionsContainerProps | ImageSuggestionsHeadingProps,
  nextProps: ImageSuggestionsContainerProps | ImageSuggestionsHeadingProps
) => {
  // only render when user is actively searching - result inverted because of memo
  return !(previousProps.isShowing || nextProps.isShowing);
};
