import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import throttle from 'lodash/throttle';
import dynamic from 'next/dynamic';
import React, {
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import {
  InputProps,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import { Configure, connectAutoComplete } from 'react-instantsearch-dom';

import ResultsDropdown from './ResultsDropdown';
import SearchInput from './SearchInput';
import SuggestedItemText from './SuggestedItemText';
import { getAllHits, isSearchTermValid } from './utils';
import DarkScreenOverlay from 'components/ScreenOverlays/DarkScreenOverlay';

import { ITEM_TYPES } from 'lib/algolia/types';
import withAlgolia from 'lib/algolia/withAlgolia';
import { trackProductsSearched } from 'lib/analytics';
import {
  navigateToBrandPage,
  navigateToCategoryPLPPage,
  navigateToCollectionPage,
  navigateToDynamicPage,
  navigateToEditorialPage,
  navigateToProductPage,
  navigateToProfilePage,
  navigateToSearchResults,
  navigateToTastemakerDetailPage,
} from 'lib/routes/redirects';
import Logger from 'lib/utils/Logger';

import { SearchBarProps, SearchBarReducerState, Suggestion } from './types';

import styles from './SearchBar.module.scss';

const AutoSuggest = dynamic(() => import('react-autosuggest'), {
  ssr: false,
});

type OnSuggestionSelectedParams = {
  method: 'click' | 'enter';
  suggestion: Suggestion;
};

enum AutosuggestMethods {
  CLICK = 'click',
  ENTER = 'enter',
}

const THROTTLE_TIME_OUT = 100;
const DEBOUNCE_TIME_OUT = 250;
const NUM_SEARCHABLE_NAVIGATION_RESULTS = 5;

const autoCompleteTheme = {
  suggestionHighlighted: styles.suggestedItemHighlighted,
  suggestionsList: styles.suggestionsList,
};

const debouncedExecuteSearch = debounce(
  (executeSearch: (value: string) => void, newSearchTerm: string) => {
    executeSearch(newSearchTerm);
  },
  DEBOUNCE_TIME_OUT
);

const searchBarReducer = (
  state: SearchBarReducerState,
  action: {
    payload: SearchBarReducerState[keyof SearchBarReducerState];
    type: keyof SearchBarReducerState;
  }
) => {
  const { payload, type } = action;
  return { ...state, [type]: payload };
};

const createAction = (
  payloadField: SearchBarReducerState[keyof SearchBarReducerState],
  typeField: keyof SearchBarReducerState
) => {
  return {
    payload: payloadField,
    type: typeField,
  };
};

type AutosuggestInputElement = typeof AutoSuggest &
  Partial<{ input: HTMLInputElement }>;

export const SearchBar = (props: SearchBarProps): ReactElement => {
  const initialState: SearchBarReducerState = {
    isSearchSuggestionSelected: false,
    searchTerm: props.searchTerm || '',
    tastemakersData: [],
    userHasFocusedInput: false,
  };

  const [searchBar, dispatch] = useReducer(searchBarReducer, initialState);

  const input = useRef<HTMLInputElement>();
  const resultsDropdownContainer = useRef<HTMLElement>();

  // useCallback required for throttle to retain reference across rerenders
  const callback = throttle(({ suggestion }: { suggestion: unknown }) => {
    const newSearchSuggestionSelected = !isNil(suggestion);
    if (searchBar.isSearchSuggestionSelected !== newSearchSuggestionSelected) {
      dispatch(
        createAction(newSearchSuggestionSelected, 'isSearchSuggestionSelected')
      );
    }
  }, THROTTLE_TIME_OUT);
  const onSuggestionHighlighted = useCallback(callback, [
    searchBar.isSearchSuggestionSelected,
    callback,
  ]);

  const onSuggestionsFetchRequested = ({
    value: newSearchTerm,
  }: {
    value: string;
  }) => {
    debouncedExecuteSearch(executeSearch, newSearchTerm);
  };

  const blurInput = () => {
    if (input.current) {
      input.current.blur();
    } else {
      Logger.warn('Searchbar input ref does not exist');
    }
  };

  // useCallback required to avoid rerenders of children component receiving function
  const closeSuggestionsContainer = useCallback(() => {
    dispatch(createAction(false, 'userHasFocusedInput'));
  }, []);

  const executeSearch = (newSearchTerm: string) => {
    // the connected props will trigger this with an empty searchTerm
    if (!isSearchTermValid(newSearchTerm)) {
      return;
    }

    // passes searchTerm to Algolia for search results in products
    if (props.refine) {
      props.refine(newSearchTerm);
    }
  };

  // The string that will be displayed in the search bar, when user is hovered on that result
  const getSuggestionValue = (hit: Suggestion): string => {
    switch (hit.itemType) {
      case ITEM_TYPES.ALGOLIA_PRODUCT: {
        return hit.product.title;
      }
      case ITEM_TYPES.ALGOLIA_PROFILE: {
        return hit.ownerName;
      }
      case ITEM_TYPES.ALGOLIA_SEARCHABLE_NAVIGATION: {
        return hit.name;
      }
    }
  };

  const onChange = (
    _: React.ChangeEvent,
    { newValue }: { newValue: string }
  ) => {
    dispatch(createAction(newValue, 'searchTerm'));
  };

  const onFocus = () => {
    dispatch(createAction(true, 'userHasFocusedInput'));
  };

  const performSearchAndNavigateToResults = () => {
    if (isSearchTermValid(searchBar.searchTerm)) {
      navigateToSearchResults(searchBar.searchTerm);
      trackProductsSearched(searchBar.searchTerm);
      blurInput();
      closeSuggestionsContainer();
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    // Navigate to search results page on ENTER
    //  - if search suggestion is highlighted, onSuggestionSelected will handle instead
    if (
      event.key === 'Enter' &&
      !searchBar.isSearchSuggestionSelected &&
      isSearchTermValid(searchBar.searchTerm)
    ) {
      performSearchAndNavigateToResults();
    }
  };

  const onOverlayClicked = () => {
    closeSuggestionsContainer();
  };

  const onSuggestionCleared = () => {
    dispatch(createAction('', 'searchTerm'));
  };

  const onSuggestionSelected = (
    _: React.FormEvent,
    { method, suggestion }: OnSuggestionSelectedParams
  ) => {
    // item selected with keyboard
    if (method === AutosuggestMethods.ENTER) {
      switch (suggestion.itemType) {
        case ITEM_TYPES.ALGOLIA_PRODUCT:
          navigateToProductPage(
            suggestion.product.brandSlug,
            suggestion.product.familySlug,
            suggestion.product.slug,
            suggestion.product.sid
          );
          break;
        case ITEM_TYPES.ALGOLIA_PROFILE:
          navigateToProfilePage(suggestion.ownerName);
          break;
        case ITEM_TYPES.ALGOLIA_SEARCHABLE_NAVIGATION:
          switch (suggestion.entityType) {
            case 'brand':
              navigateToBrandPage(suggestion.slug);
              break;
            case 'category':
              navigateToCategoryPLPPage(suggestion.slug);
              break;
            case 'collection':
              navigateToCollectionPage(suggestion.slug);
              break;
            case 'editorial':
              navigateToEditorialPage(suggestion.slug);
              break;
            case 'page':
              navigateToDynamicPage(suggestion.slug);
              break;
            case 'tastemaker':
              navigateToTastemakerDetailPage(suggestion.slug);
              break;
            default:
              Logger.error(`Unknown entityType: ${suggestion.entityType}`);
          }
          break;
      }
    }

    blurInput();

    dispatch(createAction('', 'searchTerm'));
    dispatch(createAction(false, 'userHasFocusedInput'));
  };

  const handleCloseButtonClick = () => {
    onSuggestionCleared();
    if (typeof props.onCloseButtonClick === 'function') {
      props.onCloseButtonClick.call(null);
    }
  };

  const shouldRenderSuggestions = (value: string | undefined) => {
    const newSearchTerm = value || '';
    return (
      searchBar.userHasFocusedInput &&
      !(props.disableSuggestions || false) &&
      newSearchTerm.trim().length >= 1
    );
  };

  const suggestionAttribute = (itemType: ITEM_TYPES) => {
    switch (itemType) {
      case ITEM_TYPES.ALGOLIA_PRODUCT:
        return 'product.title';
      case ITEM_TYPES.ALGOLIA_PROFILE:
        return 'ownerName';
      case ITEM_TYPES.ALGOLIA_SEARCHABLE_NAVIGATION:
        return 'name';
      default:
        return '';
    }
  };

  const renderSuggestion = (hit: Suggestion) => {
    // algolia throws search results at us without even a searchTerm
    // disregard any search results we get while the search term is invalid (empty)
    if (!isSearchTermValid(searchBar.searchTerm)) {
      return null;
    }

    return (
      <SuggestedItemText
        attribute={suggestionAttribute(hit.itemType)}
        hit={hit}
        itemType={hit.itemType}
      />
    );
  };

  const renderInputComponent = (newInputProps: InputProps<Suggestion>) => {
    return (
      <SearchInput
        {...newInputProps}
        ariaControlsId={resultsDropdownContainer.current?.id}
        isSearchShowing={props.isSearchShowing}
        onCloseButtonClick={handleCloseButtonClick}
        onIconClick={performSearchAndNavigateToResults}
        shouldBeFocused={props.shouldBeFocused}
        showCloseButton={props.showCloseButton}
      />
    );
  };

  const shouldRenderSuggestionsBool = shouldRenderSuggestions(
    searchBar.searchTerm
  );
  const { setIsShowing } = props;
  useEffect(() => {
    setIsShowing(shouldRenderSuggestionsBool);
  }, [shouldRenderSuggestionsBool, setIsShowing]);

  const renderSuggestionsContainer = ({
    children,
    containerProps,
  }: RenderSuggestionsContainerParams) => {
    resultsDropdownContainer.current = containerProps.ref;

    return (
      <ResultsDropdown
        {...containerProps}
        closeSuggestionsContainer={closeSuggestionsContainer}
        isShowing={shouldRenderSuggestionsBool}
      >
        {children}
      </ResultsDropdown>
    );
  };

  // https://github.com/moroshko/react-autosuggest/blob/master/FAQ.md#how-do-i-get-the-input-element
  const storeInputReference = (autosuggest: AutosuggestInputElement) => {
    if (autosuggest !== null) {
      input.current = autosuggest.input;
    }
  };

  const allHits = getAllHits(
    props.hits,
    searchBar.searchTerm,
    NUM_SEARCHABLE_NAVIGATION_RESULTS
  );
  const inputProps = {
    onChange,
    onFocus,
    onKeyDown,
    // keep input focused as long as the results container is open
    shouldBeFocused: shouldRenderSuggestionsBool,
    value: searchBar.searchTerm,
  };

  return (
    <div>
      <Configure clickAnalytics hitsPerPage={10} />
      <AutoSuggest
        alwaysRenderSuggestions
        focusInputOnSuggestionClick={false}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionHighlighted={onSuggestionHighlighted}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        ref={storeInputReference}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        suggestions={allHits}
        theme={autoCompleteTheme}
      />

      {shouldRenderSuggestionsBool && (
        <DarkScreenOverlay
          className={styles.overlay}
          onClick={onOverlayClicked}
        />
      )}
    </div>
  );
};

// TODO: NEXTJS12 - Refactor withAlgolia HOC
export default withAlgolia(connectAutoComplete(SearchBar), true, false, true);

export const SearchBarErrorFallback = (): ReactElement => <SearchInput />;
