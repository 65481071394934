import capitalize from 'lodash/capitalize';
import { Fragment } from 'react';
import { connectHighlight } from 'react-instantsearch-dom';

import {
  HighlightProductProps,
  HighlightProfileProps,
  HighlightSearchableNavigationProps,
  ITEM_TYPES,
  ParsedHitProps,
} from 'lib/algolia/types';
import {
  BrandLink,
  CategoryLink,
  CollectionLink,
  DynamicPageLink,
  EditorialLink,
  ProductLink,
  ProfileLink,
  TastemakersLink,
} from 'lib/links';
import { ProductLinkProps } from 'lib/links/_productLink';
import Logger from 'lib/utils/Logger';

import styles from './SuggestedItemText.module.scss';

type HighlightProps =
  | HighlightSearchableNavigationProps
  | HighlightProductProps
  | HighlightProfileProps;

const highlightParsedHit = (parsedHit: ParsedHitProps[]) => (
  <Fragment>
    {parsedHit.map(part =>
      part.isHighlighted ? (
        <span className={styles.highlight} key={part.value}>
          {part.value}
        </span>
      ) : (
        <span key={part.value}>{part.value}</span>
      )
    )}
  </Fragment>
);

const getHighlightedText = ({
  attribute,
  highlight,
  hit,
}:
  | HighlightSearchableNavigationProps
  | HighlightProductProps
  | HighlightProfileProps) => {
  const parsedHit = highlight({
    attribute,
    highlightProperty: '_highlightResult',
    hit,
  });
  return highlightParsedHit(parsedHit);
};

const renderProduct = (props: HighlightProductProps) => {
  const highlightedText = getHighlightedText(props);
  const {
    product: {
      brandSlug,
      familySlug,
      plpOptions: options,
      sid: productId,
      slug: productSlug,
    },
    selectedOptions,
    sid: variantSid,
  } = props.hit;
  const productLinkProps: Omit<ProductLinkProps, 'children'> = {
    brandSlug,
    familySlug,
    hierarchicalCategories: props.hit?.product?.hierarchicalCategories,
    options,
    productId,
    productSlug,
    selectedVariant: {
      selectedOptions,
      sid: variantSid,
    },
  };

  return (
    <ProductLink {...productLinkProps}>
      <a className={styles.link} href="placeholder">
        <div className={styles.container}>{highlightedText}</div>
      </a>
    </ProductLink>
  );
};

const renderProfile = (props: HighlightProfileProps) => {
  const highlightedText = getHighlightedText(props);
  const { displayName, objectID, ownerName, type } = props.hit;

  const profileLinkProps = {
    profile: {
      customers: {
        customers: [],
      },
      displayName,
      id: objectID,
      type,
      username: ownerName,
    },
  };

  return (
    <ProfileLink {...profileLinkProps}>
      <a className={styles.link} href="placeholder">
        <div className={styles.container}>
          {highlightedText}
          <span className={styles.hint}> - Creator</span>
        </div>
      </a>
    </ProfileLink>
  );
};

const renderSearchableNavigation = (
  props: HighlightSearchableNavigationProps
) => {
  const highlightedText = getHighlightedText(props);
  const { entityType, slug } = props.hit;

  const linkContent = (
    <a className={styles.link} href="placeholder">
      <div className={styles.container}>
        {highlightedText}
        <span className={styles.hint}> - {capitalize(entityType)}</span>
      </div>
    </a>
  );
  switch (entityType) {
    case 'brand':
      return <BrandLink brandSlug={slug}>{linkContent}</BrandLink>;
    case 'category':
      return (
        <CategoryLink categorySlug={slug} passHref>
          {linkContent}
        </CategoryLink>
      );
    case 'collection':
      return (
        <CollectionLink collectionSlug={slug}>{linkContent}</CollectionLink>
      );
    case 'editorial':
      return <EditorialLink slug={slug}>{linkContent}</EditorialLink>;
    case 'page':
      return <DynamicPageLink pageSlug={slug}>{linkContent}</DynamicPageLink>;
    case 'tastemaker':
      return <TastemakersLink slug={slug}>{linkContent}</TastemakersLink>;
    default:
      Logger.error(`Unknown SearchableNavigation type ${entityType}`);
      return null;
  }
};

// this must remain a pure function (https://github.com/moroshko/react-autosuggest#rendersuggestion-required)
const SuggestedItemText = (props: HighlightProps) => {
  try {
    switch (props.itemType) {
      case ITEM_TYPES.ALGOLIA_PRODUCT: {
        return renderProduct(props);
      }
      case ITEM_TYPES.ALGOLIA_PROFILE: {
        return renderProfile(props);
      }
      case ITEM_TYPES.ALGOLIA_SEARCHABLE_NAVIGATION: {
        return renderSearchableNavigation(props);
      }
    }
  } catch (e) {
    Logger.error(`Cannot render search result with type '${props.itemType}'`);
  }
  return null;
};

export default connectHighlight(SuggestedItemText);
