import classnames from 'classnames';
import { HTMLAttributes, memo } from 'react';

import styles from './DarkScreenOverlay.module.scss';

type DarkScreenOverlayProps = HTMLAttributes<HTMLDivElement>;

const DarkScreenOverlay = ({
  className,
  ...restProps
}: DarkScreenOverlayProps) => (
  <div className={classnames(className, styles.container)} {...restProps} />
);

export default memo(DarkScreenOverlay);
