import classnames from 'classnames';
import omit from 'lodash/omit';
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import IconButton from 'components/Buttons/IconButton';

import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';
import { mergeRefs } from 'lib/utils/ref';

import CloseIcon from 'assets/icons/ic-close.inline.svg';
import SearchIcon from 'assets/icons/ic-search.inline.svg';

import styles from './SearchInput.module.scss';

const DEFAULT_PLACEHOLDER = 'What are you looking for?';
const LABEL_SEARCH = 'Begin typing in order to get a list of matching products';

export const TEST_ID_SEARCH_BAR_INPUT = 'TEST_ID_SEARCH_BAR_INPUT';

export type SearchInputProps = {
  [key: string]: unknown;
  ariaControlsId?: string;
  isSearchShowing: boolean;
  onBlur?(event: FocusEvent): void;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onCloseButtonClick(): void;
  onFocus?(event: FocusEvent): void;
  onIconClick(): void;
  onKeyDown?(event: KeyboardEvent): void;
  shouldBeFocused?: boolean;
  value: string;
};

export const SearchInput = forwardRef(
  (inputProps: SearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [isFocused, setFocused] = useState(inputProps.shouldBeFocused);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      // execute focus input element if this control is rendered with shouldBeFocused = true
      if (
        inputProps.shouldBeFocused &&
        inputRef?.current &&
        typeof inputRef?.current?.focus === 'function'
      ) {
        inputRef.current.focus();
      }
    }, [inputProps.shouldBeFocused]);

    const onFocus = (event: FocusEvent) => {
      setFocused(true);
      if (inputProps.onFocus) {
        inputProps.onFocus.call(null, event);
      }
    };

    const onBlur = (event: FocusEvent) => {
      setFocused(false);
      if (inputProps.onBlur) {
        inputProps.onBlur.call(null, event);
      }
      setTimeout(() => {
        window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
      }, 0);
    };

    const onCloseButtonClick = () => {
      if (inputProps.onCloseButtonClick) {
        inputProps.onCloseButtonClick.call(null);
      }
    };

    const rootClasses = classnames(styles.root, {
      [styles.isFocused]: isFocused || inputProps.shouldBeFocused,
      [styles.isSearchShowing]: inputProps.isSearchShowing,
    });

    // take out invalid DOM props
    const sanitizedInputProps = omit(inputProps, [
      'ariaControlsId',
      'isSearchShowing',
      'onIconClick',
      'shouldBeFocused',
      'showCloseButton',
      'onCloseButtonClick',
    ]);

    const shouldShowCloseButton =
      typeof inputProps.onCloseButtonClick === 'function';

    return (
      <div className={rootClasses}>
        {shouldShowCloseButton && (
          <IconButton
            IconComponent={CloseIcon}
            aria-label="Close Search"
            className={styles.clearIconButton}
            onClick={onCloseButtonClick}
          />
        )}
        <input
          aria-autocomplete="list"
          aria-controls={inputProps.ariaControlsId}
          aria-expanded={!!inputProps.value}
          aria-haspopup="true"
          aria-label={LABEL_SEARCH}
          className={styles.input}
          data-test-id={TEST_ID_SEARCH_BAR_INPUT}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={DEFAULT_PLACEHOLDER}
          ref={mergeRefs([inputRef, ref])}
          role="combobox"
          {...sanitizedInputProps}
        />
        <div
          aria-label="Search"
          className={styles.icon}
          onClick={inputProps.onIconClick}
          onKeyPress={keyPressHandler({ Enter: inputProps.onIconClick })}
          role="button"
          tabIndex={0}
        >
          <SearchIcon />
        </div>
      </div>
    );
  }
);
SearchInput.displayName = 'SearchInput';

export default SearchInput;
