import uniqBy from 'lodash/uniqBy';

import {
  AlgoliaSearchResult,
  ITEM_TYPES,
  ProductHit,
  ProfileHit,
  SearchableNavigationHit,
} from 'lib/algolia/types';
import {
  SEARCHABLE_NAVIGATIONS_INDEX_NAME,
  CONTENT_OWNER_INDEX_NAME,
} from 'lib/algolia/withAlgolia';
import Logger from 'lib/utils/Logger';

import { Suggestion } from './types';
import { ExplorerContentOwnerType } from 'types/generated/api';

const NUM_PRODUCT_RESULTS = 5;
const NUM_PROFILE_RESULTS = 5;

export const isSearchTermValid = (searchTerm: string): boolean => {
  return (searchTerm || '').trim().length >= 1;
};

function productsToSuggestions(hits: ProductHit[]): Suggestion[] {
  try {
    return uniqBy(hits, hit => hit.productSid)
      .slice(0, NUM_PRODUCT_RESULTS)
      .map(hit => ({
        ...hit,
        itemType: ITEM_TYPES.ALGOLIA_PRODUCT,
      }));
  } catch (e) {
    Logger.error(`Cannot process hits with ProductHit: ${e}`);
    return [];
  }
}

function profilesToSuggestions(hits: ProfileHit[]): Suggestion[] {
  try {
    return uniqBy(hits, hit => hit.ownerName)
      .slice(0, NUM_PROFILE_RESULTS)
      .map(hit => ({
        ...hit,
        itemType: ITEM_TYPES.ALGOLIA_PROFILE,
      }));
  } catch (e) {
    Logger.error(`Cannot process hits with ProfiletHit: ${e}`);
    return [];
  }
}

function searchableNavigationToSuggestions(
  hits: SearchableNavigationHit[],
  numResults: number
): Suggestion[] {
  try {
    return uniqBy(hits, hit => hit.slug)
      .slice(0, numResults)
      .map(hit => ({
        itemType: ITEM_TYPES.ALGOLIA_SEARCHABLE_NAVIGATION,
        ...hit,
      }));
  } catch (e) {
    Logger.error(
      `Cannot process hits with ITEM_TYPE SearchableNavigations: ${e}`
    );
    return [];
  }
}

export const getAllHits = (
  algoliaResults: AlgoliaSearchResult[] | undefined,
  searchTerm: string,
  numResults: number
): Suggestion[] => {
  // algolia throws search results at us without even a searchTerm
  // disregard any search results we get while the search term is invalid (empty)
  if (!algoliaResults) {
    return [];
  }

  const validateProductHit = (hit: ProductHit) => {
    try {
      return (
        hit.productSid &&
        hit.product &&
        hit.product.brandSlug &&
        hit.product.slug &&
        hit.product.sid &&
        hit.product.familySlug
      );
    } catch (e) {
      Logger.error(`Malformed Algolia Product result: ${JSON.stringify(hit)}`);
      return false;
    }
  };

  const getProductHits = () => {
    const hits = (algoliaResults.find(
      hit =>
        hit.index !== SEARCHABLE_NAVIGATIONS_INDEX_NAME &&
        hit.index !== CONTENT_OWNER_INDEX_NAME
    )?.hits ?? []) as ProductHit[];
    return hits.filter(validateProductHit);
  };

  const productHits = !isSearchTermValid(searchTerm)
    ? []
    : productsToSuggestions(getProductHits());

  const isValidProfileHit = (hit: ProfileHit): boolean => {
    try {
      if (!hit.ownerName) {
        return false;
      }

      // TODO: enable user profile. https://verishop.atlassian.net/browse/SPE-2961
      return (
        // hit.type === ExplorerContentOwnerType.USER ||
        hit.type === ExplorerContentOwnerType.INFLUENCER
      );
    } catch (e) {
      Logger.error(`Malformed Algolia Profile result: ${JSON.stringify(hit)}`);
      return false;
    }
  };

  const getProfileHits = () => {
    const hits = (algoliaResults.find(
      hit => hit.index === CONTENT_OWNER_INDEX_NAME
    )?.hits ?? []) as ProfileHit[];
    return hits.filter(isValidProfileHit);
  };

  const profileHits = !isSearchTermValid(searchTerm)
    ? []
    : profilesToSuggestions(getProfileHits());

  const validateSearchableNavigationHit = (hit: SearchableNavigationHit) => {
    try {
      return hit.name && hit.slug && hit.entityType;
    } catch (e) {
      Logger.error(`Malformed Algolia Product result: ${JSON.stringify(hit)}`);
      return false;
    }
  };

  const getSearchableNavigationHits = () => {
    const hits = (algoliaResults.find(
      hit => hit.index === SEARCHABLE_NAVIGATIONS_INDEX_NAME
    )?.hits ?? []) as SearchableNavigationHit[];
    return hits.filter(validateSearchableNavigationHit);
  };

  const searchableNavigationHits = !isSearchTermValid(searchTerm)
    ? []
    : searchableNavigationToSuggestions(
        getSearchableNavigationHits(),
        numResults
      );

  return [...searchableNavigationHits, ...productHits, ...profileHits];
};
