import Router from 'next/router';

import { pageRoutes } from 'lib/routes';

const scrollToTop = () => {
  if (window) {
    window.scrollTo(0, 0);
  }
};

export const navigateToCollectionPage = (collectionSlug: string) => {
  const displayUrl = pageRoutes.collection.displayUrl(collectionSlug);
  const internalUrl = pageRoutes.collection.internalUrl(collectionSlug);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToCategoryPLPPage = (categorySlug: string) => {
  const displayUrl = pageRoutes.category.plp.displayUrl(categorySlug);
  const internalUrl = pageRoutes.category.plp.internalUrl(categorySlug);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToBrandPage = (brandSlug: string) => {
  const displayUrl = pageRoutes.brand.detail.landing.displayUrl(brandSlug);
  const internalUrl = pageRoutes.brand.detail.landing.internalUrl(brandSlug);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToDynamicPage = (pageSlug: string) => {
  const displayUrl = pageRoutes.dynamicPage.displayUrl(pageSlug);
  const internalUrl = pageRoutes.dynamicPage.internalUrl(pageSlug);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToEditorialPage = (editorialSlug: string) => {
  const displayUrl = pageRoutes.editorial.displayUrl({
    slug: editorialSlug,
  });
  const internalUrl = pageRoutes.editorial.internalUrl(editorialSlug);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToProductPage = (
  brandSlug: string,
  familySlug: string,
  productSlug: string,
  productSid: string
) => {
  const routeData = {
    brandSlug,
    familySlug,
    productSid,
    productSlug,
  };
  const displayUrl = pageRoutes.productDetail.displayUrl(routeData);
  const internalUrl = pageRoutes.productDetail.internalUrl(routeData);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToSearchResults = (searchTerm: string) => {
  const displayUrl = pageRoutes.search.displayUrl(searchTerm);
  const internalUrl = pageRoutes.search.internalUrl(searchTerm);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToTastemakerDetailPage = (tastemakerSlug: string) => {
  const displayUrl = pageRoutes.tastemakers.detail.displayUrl(tastemakerSlug);
  const internalUrl = pageRoutes.tastemakers.detail.internalUrl(tastemakerSlug);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};

export const navigateToProfilePage = (username: string) => {
  const displayUrl = pageRoutes.profile.landing.displayUrl({ username });
  Router.push(displayUrl).then(scrollToTop);
};

export const navigateToAccountTabPage = (tab: string) => {
  const displayUrl = pageRoutes.account.profile.tab.displayUrl(tab);
  const internalUrl = pageRoutes.account.profile.tab.internalUrl(tab);
  Router.push(internalUrl, displayUrl).then(scrollToTop);
};
