import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import { memo } from 'react';
import { connectHits } from 'react-instantsearch-dom';

import ImageSuggestionsHeading from './ImageSuggestionsHeading';
import { ImageSuggestionsContainerProps, isUserSearching } from './utils';
import NoImagePlaceholder from 'components/NoImagePlaceholder/NoImagePlaceholder';

import { ProductVariant } from 'lib/algolia/types';
import { generateImageSrcAttributes } from 'lib/image';
import { BrandLink, ProductLink } from 'lib/links';

import styles from './ImageSuggestionsContainer.module.scss';

const IMAGE_MAX_WIDTH = 200;
const NUM_HITS_DISPLAYED = 3; // number of hits to display in the ImageSuggestionsContainer

const renderItem = (
  {
    galleryImages,
    modelImages,
    plpGroupId,
    product: {
      brand,
      brandSlug,
      familySlug,
      hierarchicalCategories,
      plpOptions: options,
      sid,
      slug,
      title,
    },
    productImages,
    selectedOptions,
    sid: variantSid,
  }: ProductVariant,
  onClick: () => void
) => {
  const firstModelImage = modelImages?.[0]?.url;
  const firstProductImage = productImages?.[0]?.url;
  const firstGalleryImage = galleryImages?.[0]?.url;
  const imageToUse = firstModelImage || firstProductImage || firstGalleryImage;
  const imgSrcAttrbutes = generateImageSrcAttributes({
    url: imageToUse,
    width: IMAGE_MAX_WIDTH,
  });
  const productLinkProps = {
    brandSlug,
    familySlug,
    hierarchicalCategories,
    options,
    productId: sid,
    productSlug: slug,
    queryStringParams: variantSid ? { variant_id: variantSid } : undefined,
    selectedVariant: variantSid
      ? { selectedOptions, sid: variantSid }
      : undefined,
  };

  return (
    <div className={styles.item} key={plpGroupId}>
      <div className={styles.image}>
        <ProductLink {...productLinkProps}>
          <a href="placeholder" onClick={onClick}>
            {imageToUse ? (
              <img alt={productLinkProps.productSlug} {...imgSrcAttrbutes} />
            ) : (
              <NoImagePlaceholder className={styles.noImagePlaceholder} />
            )}
          </a>
        </ProductLink>
      </div>
      <div className={styles.brandName}>
        <BrandLink brandSlug={brandSlug}>
          <a className={styles.textLink} href="placeholder" onClick={onClick}>
            {brand}
          </a>
        </BrandLink>
      </div>
      <div className={styles.productName}>
        <ProductLink {...productLinkProps}>
          <a className={styles.textLink} href="placeholder" onClick={onClick}>
            {title}
          </a>
        </ProductLink>
      </div>
    </div>
  );
};

export const ImageSuggestionsContainer = memo(
  ({
    closeSuggestionsContainer,
    hits,
    isShowing,
  }: ImageSuggestionsContainerProps) => {
    const onClick = () => closeSuggestionsContainer();
    const hasHits = !isEmpty(hits);
    const imageSuggestionsHeadingProps = {
      isShowing,
    };
    return (
      <div className={styles.container}>
        <ImageSuggestionsHeading {...imageSuggestionsHeadingProps} />
        {hasHits && (
          <div className={styles.itemContainer}>
            {take(hits, NUM_HITS_DISPLAYED).map(item =>
              renderItem(item, onClick)
            )}
          </div>
        )}
      </div>
    );
  },
  isUserSearching
);

ImageSuggestionsContainer.displayName = 'ImageSuggestionsContainer';

// TODO: NextJS12 - Fix input type.
export default connectHits(ImageSuggestionsContainer);
