import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

import { ImageSuggestionsHeadingProps, isUserSearching } from './utils';

import styles from './ImageSuggestionsHeading.module.scss';

export const ImageSuggestionsHeading = memo(
  ({ searchResults }: ImageSuggestionsHeadingProps) => {
    // waiting for algolia response
    if (!searchResults) {
      return null;
    }

    const { hits, query } = searchResults;

    // no results for current search term
    if (isEmpty(hits)) {
      return null;
    }

    return (
      <div className={styles.text}>
        Top results for{' '}
        <span className={styles.searchTerm}>&quot;{query}&quot;</span>
      </div>
    );
  },
  isUserSearching
);

ImageSuggestionsHeading.displayName = 'ImageSuggestionsHeading';

// TODO: NEXTJS12 - Fix input type.
export default connectStateResults(ImageSuggestionsHeading);
